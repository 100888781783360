document.addEventListener('turbolinks:load', () => {

    const filterOrganizationMediaEntriesSelect = document.getElementById('filter_media_entries_organization')
    const filterTypeMediaEntriesSelect = document.getElementById('filter_media_entries_type')
    const stationMediaFilterTag = document.getElementById('station_media_filter_tag')
    const searchMediaEntries = document.getElementById('search_media_entries')
    const filterMediaEntriesDelete = document.getElementById('filter_media_entries_delete')
    const nextPage = document.getElementById('next_page_ajax')
    const prevPage = document.getElementById('prev_page_ajax')
    let currentPageLink = document.getElementById('current_page_ajax')

    if (filterTypeMediaEntriesSelect != null && searchMediaEntries != null && stationMediaFilterTag !== null &&
        filterMediaEntriesDelete !== null &&
        nextPage !== null && currentPageLink !== null && prevPage != null) {
        init()
    }

    function init() {

        // next page
        $(document).on('click', '#next_page_ajax', function(e) {
            e.preventDefault()
            currentPageLink = document.getElementById('current_page_ajax')
            const page = parseInt(getParameterByName("page", currentPageLink.href)) +1
            search_request(page)
            return false
        });

        // previous page
        $(document).on('click', '#prev_page_ajax', function(e) {
            e.preventDefault()
            currentPageLink = document.getElementById('current_page_ajax')
            const page = parseInt(getParameterByName("page", currentPageLink.href)) -1
            search_request(page)
            return false
        });

        // selected page
        $(document).on('click', '.page-select-ajax', function(e) {
            e.preventDefault()
            const page = parseInt(getParameterByName("page", this.href))
            search_request(page)
            return false
        });

        // selected entries per page
        // New search request for using new configuration
        $(document).on('click', '.media-per-page', function(e) {
            e.preventDefault()
            set_page_limit_and_search(e.currentTarget.href)
            return false
        });

        // type search
        filterTypeMediaEntriesSelect.addEventListener('change', function (event) {
            search_request(1)
        })

        // tag search
        stationMediaFilterTag.addEventListener('change', function () {
            search_request(1)
        })

        // text search
        searchMediaEntries.addEventListener('input', function (event) {
            search_request(1)
        })

        // organization search
        if (filterOrganizationMediaEntriesSelect != null){
            if(filterOrganizationMediaEntriesSelect.value !== "all") {
                only_organization_tags(filterOrganizationMediaEntriesSelect.value)
            }
            filterOrganizationMediaEntriesSelect.addEventListener('change', function (event) {
                search_request(1)
                only_organization_tags(event.target.value)
        })}

        filterMediaEntriesDelete.addEventListener('click', function (event) {
            filterTypeMediaEntriesSelect.getElementsByTagName('option')[0].selected = true
            stationMediaFilterTag.getElementsByTagName('option')[0].selected = true
            searchMediaEntries.value = ""
            if (filterOrganizationMediaEntriesSelect != null) {
                filterOrganizationMediaEntriesSelect.getElementsByTagName('option')[0].selected = true
            }
            search_request(1)
        })
    }

    // ajax search request
    function search_request(page) {
        let query = searchMediaEntries.value.trim()
        if(query === "") {
            query = null
        }

        let organizationFilter;
        if (filterOrganizationMediaEntriesSelect == null) {
            organizationFilter = "all"
        } else {
            organizationFilter = filterOrganizationMediaEntriesSelect.value
        }

        $.rails.ajax({
            url: "/media_entries/search/" + query + "/organization/" + organizationFilter + "/type/" +
                filterTypeMediaEntriesSelect.value + "/tag/" + stationMediaFilterTag.value + "/page/" + page,
            type: "get",
            data: "",
        })
    }

    function set_page_limit_and_search(link){
        $.rails.ajax({
            url: link,
            type: "get",
            data: "",
            success: function() {
                search_request(1)
            },
        })
    }

    // get page from url query param
    function getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function only_organization_tags(org_name) {
        if(org_name === 'all') {
            for (let option of stationMediaFilterTag.options) {
                option.classList.remove('hidden')
            }
        } else {
            for (let option of stationMediaFilterTag.options) {
                if(option.value === "all" || option.value === "no_tag"){
                    continue
                }
                let optionHtmlArray = option.innerHTML.split(' ')
                //let option_org = optionHtmlArray[optionHtmlArray.length -1].slice(1,-1) //Take last part and get rid of the bracket
                let option_org = option.dataset["organization"]
                if(option_org === org_name){
                    option.classList.remove('hidden')
                } else {
                    option.classList.add('hidden')
                }
            }
        }
    }
});