document.addEventListener('turbolinks:load', () => {

    const infoMediaEntryTableBody = document.getElementById('infoMediaEntryTableBody')
    const infoMediaTable = document.getElementById('infoMediaTable')
    if (infoMediaEntryTableBody !== null) {

        let current = null
        let tableRows = infoMediaEntryTableBody.getElementsByTagName("tr");

        for (let tr of tableRows) {
            tr.draggable = true;

            // START
            tr.addEventListener("dragstart", function (ev) {
                current = this;
                //Remove the alternate-children class, because this bg-color gets used otherwise and the added color classes don't work
                infoMediaTable.classList.remove('alternate-children')

                // style other elements
                for (let it of tableRows) {
                    if (it !== current) {
                        it.classList.add("bg-amber-200")
                    }
                }
            });

            // ENTER
            tr.addEventListener("dragenter", function (ev) {
                for (let it of tableRows) {
                    if (current !== null) {
                        if (it !== tr && it !== current) {
                            // other stations
                            it.classList.add("bg-amber-200")
                            it.classList.remove("bg-red-200")
                        } else if (it === tr && it !== current) {
                            // reached station
                            it.classList.add("bg-red-200")
                            it.classList.remove("bg-amber-200")
                        }
                    }
                }
            });

            //LEAVE
            tr.addEventListener("dragleave", function () {
            });

            // END
            tr.addEventListener("dragend", function (ev) {
                // reset everything
                current = null
                infoMediaTable.classList.add('alternate-children')
                for (let it of tableRows) {
                    it.classList.remove("bg-amber-200")
                    it.classList.remove("bg-red-200")
                }
            });

            // OVER
            tr.addEventListener("dragover", function (evt) {
                evt.preventDefault();
            });

            // DROP
            tr.addEventListener("drop", function (evt) {
                evt.preventDefault();
                if (this !== current) {
                    let currentPos = 0;
                    let droppedPos = 0;
                    for (let it=0; it<tableRows.length; it++) {
                        if (current === tableRows[it]) {
                            currentPos = it;
                        }
                        if (this === tableRows[it]) {
                            droppedPos = it;
                        }
                    }

                    if (currentPos < droppedPos) {
                        this.parentNode.insertBefore(current, this.nextSibling);
                    } else {
                        this.parentNode.insertBefore(current, this);
                    }
                }

            });

        }
    }


});