document.addEventListener('turbolinks:load', () => {

    const videoSelect = document.getElementById('videoSelect');
    const infoVideoControl = document.getElementById('infoVideoControl');
    const infoVideoSource = document.getElementById('infoVideoSource');

    const imageSelect = document.getElementById('imageSelect');
    const infoImageSrc = document.getElementById('infoImageSrc');

    const textSelect = document.getElementById('textSelect');
    const infoSelectedText = document.getElementById('infoSelectedText');

    const websiteSelect = document.getElementById('websiteSelect');
    const infoSelectedWebsite = document.getElementById('infoSelectedWebsite');

    if (infoVideoControl !== null && videoSelect !== null && infoVideoSource !== null &&
        imageSelect !== null && infoImageSrc !== null && textSelect !== null && infoSelectedText !== null &&
        websiteSelect !== null && infoSelectedWebsite !== null) {
        init()
    }

    function init() {

        let videoSrc = videoSelect.value.split(";")[1]
        if (videoSrc != null) {
            infoVideoSource.src = videoSrc
            infoVideoControl.load()
        }

        let imageSrc = imageSelect.value.split(";")[1]
        if (typeof imageSrc !== 'undefined' && imageSrc !== null && imageSrc !== "") {
            infoImageSrc.src = imageSrc
        }

        let html = textSelect.value.split(";")[2]
        if (typeof html !== 'undefined' && html !== null && html !== "") {
            infoSelectedText.innerHTML = html
        } else {
            infoSelectedText.innerHTML = I18n.t('js.please_select_a_text')
        }

        let url = websiteSelect.value.split(";")[2]
        if (typeof url !== 'undefined' && url !== null && url !== "") {
            infoSelectedWebsite.innerHTML =  `<a href="${url}" class="border-b border-blue-500 text-blue-500" target="_blank"> ${removeSlashes(url)} </a>`;
        } else {
            infoSelectedWebsite.innerHTML = `<i>${I18n.t('js.please_select_a_website')}</i>`
        }

        const videoSelect2 = $("#videoSelect").select2();
        videoSelect2.data('select2').$container.addClass("wrap")
        videoSelect2.on('select2:selecting', function(e) {
            const videoStationMediaEntryWarning = document.getElementById('video_station_media_entry_warning')
            if (videoStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    videoStationMediaEntryWarning.classList.add("hidden")
                } else {
                    videoStationMediaEntryWarning.classList.remove("hidden")
                    const videoStationMediaEntryWarningMsg = document.getElementById('video_station_media_entry_warning_msg')
                    if (videoStationMediaEntryWarningMsg !== null) {
                        videoStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }

            }

            videoSrc = e.params.args.data.id.split(";")[1]
            infoVideoSource.src = videoSrc
            infoVideoControl.load()
        });
        $("#videoSelect").hide()

        const imageSelect2 = $("#imageSelect").select2()
        imageSelect2.data('select2').$container.addClass("wrap")
        imageSelect2.on('select2:selecting', function(e) {
            const imageStationMediaEntryWarning = document.getElementById('image_station_media_entry_warning')
            if (imageStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    imageStationMediaEntryWarning.classList.add("hidden")
                } else {
                    imageStationMediaEntryWarning.classList.remove("hidden")
                    const imageStationMediaEntryWarningMsg = document.getElementById('image_station_media_entry_warning_msg')
                    if (imageStationMediaEntryWarningMsg !== null) {
                        imageStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }

            }

            imageSrc = e.params.args.data.id.split(";")[1]
            if (imageSrc !== "") {
                infoImageSrc.src = imageSrc
            }
        });
        $("#imageSelect").hide()

        const textSelect2 = $("#textSelect").select2();
        textSelect2.data('select2').$container.addClass("wrap")
        textSelect2.on('select2:selecting', function(e) {
            const textStationMediaEntryWarning = document.getElementById('text_station_media_entry_warning')
            if (textStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    textStationMediaEntryWarning.classList.add("hidden")
                } else {
                    textStationMediaEntryWarning.classList.remove("hidden")
                    const textStationMediaEntryWarningMsg = document.getElementById('text_station_media_entry_warning_msg')
                    if (textStationMediaEntryWarningMsg !== null) {
                        textStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }

            }

            html = e.params.args.data.id.split(";")[2]
            infoSelectedText.innerHTML = html
        });
        $("#textSelect").hide()

        const websiteSelect2 = $("#websiteSelect").select2();
        websiteSelect2.data('select2').$container.addClass("wrap")
        websiteSelect2.on('select2:selecting', function(e) {
            const websiteStationMediaEntryWarning = document.getElementById('website_station_media_entry_warning')
            if (websiteStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    websiteStationMediaEntryWarning.classList.add("hidden")
                } else {
                    websiteStationMediaEntryWarning.classList.remove("hidden")
                    const websiteStationMediaEntryWarningMsg = document.getElementById('website_station_media_entry_warning_msg')
                    if (websiteStationMediaEntryWarningMsg !== null) {
                        websiteStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }

            }

            url = e.params.args.data.id.split(";")[2]
            infoSelectedWebsite.innerHTML =  `<a href="${url}" class="border-b border-blue-500 text-blue-500" target="_blank"> ${removeSlashes(url)} </a>`;

        });
        $("#websiteSelect").hide()
    }

    function removeSlashes(url) {
        if (url[0] === "/" && url[1] === "/") {
            return url.substring(2);
        } else {
            return url
        }
    }
});